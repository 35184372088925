import React from 'react';

import Image from "./image"
import { css } from '@emotion/core';
import { RichText } from 'prismic-reactjs';

const UpcomingEvents = ({ upcomingEventsTitle, upcomingEvents }) => {
  console.log(upcomingEvents);
  return upcomingEvents?.length > 0 ? (
    <section css={UpcomingEventsCss} className="section upcoming-events-section">
        <div className="container">
            <h3 className="title is-3 has-text-centered">
              {RichText.asText(upcomingEventsTitle)}
            </h3>
            <div className="columns is-vcentered is-centered">
              {upcomingEvents.map(({
                upcoming_event_desc,
                upcoming_event_date,
                upcoming_event_image,
                upcoming_event_link,
              }) => (
                <div className="column upcoming-event-element is-4 is-centered">
                  <a 
                    href={upcoming_event_link.url}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <Image image={upcoming_event_image} />
                  </a>
                  <p className="legend has-text-centered is-uppercase has-text-weight-semibold">
                    {RichText.asText(upcoming_event_desc)}
                  </p>
                  <p className="legend has-text-centered is-uppercase has-text-weight-semibold">
                    {RichText.asText(upcoming_event_date)}
                  </p>
                </div>
              ))}
            </div>
        </div>
    </section>
  ) : null;
};

const UpcomingEventsCss = css`
  .gatsby-image-wrapper {
    text-align: center;
    img {
      max-height: 100px;
    }
  }
`

export default UpcomingEvents;